import * as internal from "../../node_modules/elix/src/base/internal.js";
import WrappedStandardElement from "../../node_modules/elix/src/base/WrappedStandardElement.js";
import { fragmentFrom } from "../../node_modules/elix/src/core/htmlLiterals.js";

class CurrentAnchor extends WrappedStandardElement.wrap("a") {
  get [internal.defaultState]() {
    return Object.assign(super[internal.defaultState], {
      areaLink: false,
      current: false,
    });
  }

  get areaLink() {
    return this[internal.state].areaLink;
  }
  set areaLink(value) {
    const parsed = String(value) === "true";
    this[internal.setState]({
      areaLink: parsed,
    });
  }

  get current() {
    return this[internal.state].current;
  }

  [internal.render](changed) {
    super[internal.render](changed);
    if (changed.current) {
      this.classList.toggle("current", this[internal.state].current);
    }
  }

  [internal.stateEffects](state, changed) {
    const effects = super[internal.stateEffects]
      ? super[internal.stateEffects](state, changed)
      : {};

    if (changed.areaLink || changed.href) {
      const { areaLink, href } = state;
      const path = window.location.pathname;
      if (href) {
        let current;
        if (areaLink) {
          // Match prefix
          let prefix = href;
          // If prefix doesn't end in slash, add a slash.
          // We want to avoid matching in the middle of a folder name.
          if (prefix.length < path.length && prefix.substr(-1) !== "/") {
            prefix += "/";
          }
          current = path.substr(0, prefix.length) === prefix;
        } else {
          // Match whole path
          current = path === href;
        }
        Object.assign(effects, { current });
      }
    }

    return effects;
  }

  get [internal.template]() {
    const result = super[internal.template];

    result.content.append(
      fragmentFrom.html`
        <style>
          #inner {
            color: inherit;
            text-decoration: inherit;
          }
        </style>
      `
    );

    return result;
  }
}

window.customElements.define("ck-current-anchor", CurrentAnchor);
export default CurrentAnchor;
