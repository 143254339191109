import * as internal from "../../node_modules/elix/src/base/internal.js";
import { fragmentFrom } from "../../node_modules/elix/src/core/htmlLiterals.js";
import HamburgerMenuButton from "../../node_modules/elix/src/plain/PlainHamburgerMenuButton.js";

class MenuButton extends HamburgerMenuButton {
  get [internal.defaultState]() {
    return Object.assign(super[internal.defaultState], {
      fromEdge: "end",
    });
  }

  get [internal.template]() {
    const result = super[internal.template];
    const defaultSlot = result.content.querySelector("slot:not([name])");
    defaultSlot.replaceWith(fragmentFrom.html`
      <style>
        #menuCommands {
          color: #4c5657;
          display: flex;
          flex-direction: column;
          font-size: 17px;
          overflow: auto;
          padding: 20px;
        }

        ck-current-anchor {
          border-left: 10px solid transparent;
          display: block;
        }

        ck-current-anchor span {
          display: inline-block;
          padding: 20px;
        }

        ck-current-anchor.current {
          border-color: #56c5be;
        }
      </style>
      <div id="menuCommands">
        <ck-current-anchor href="/" class="areaLink">
          <span>home</span>
        </ck-current-anchor>
        <ck-current-anchor href="/elix" area-link="true">
          <span>elix components</span>
        </ck-current-anchor>
        <!--
        <ck-current-anchor href="/services" class="areaLink" area-link="true">
          <span>services</span>
        </ck-current-anchor>
        -->
        <ck-current-anchor href="/blog" class="areaLink" area-link="true">
          <span>blog</span>
        </ck-current-anchor>
        <ck-current-anchor href="/about" class="areaLink" area-link="true">
          <span>about</span>
        </ck-current-anchor>
      </div>
    `);
    return result;
  }
}

customElements.define("ck-menu-button", MenuButton);
export default MenuButton;
